@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
}
.animateForm {
  animation: form 1s ease-in-out;
}
.dotted-ring {
  border: 3.5px dashed #3498db;
  border-radius: 70%;
  animation: rotate 15s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes form {
  0% {
    border-radius: 0px;
    background-color: rgba(51, 196, 249, 0.823);
  }
  25% {
    border-radius: 0px;
    background-color: rgba(51, 196, 249, 0.7);
  }
  50% {
    border-radius: 0px;
    background-color: rgba(51, 196, 249, 0.5);
  }
  100% {
    border-radius: 0px;
    background-color: white;
  }
}

.blueToYellow {
  background: linear-gradient(
    to right,
    #ffa438 0%,
    #ffa438 50%,
    #197cc1 50%,
    #197cc1 100%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  transition: background-position 0.3s ease-in-out;
}

.blueToYellow:hover {
  background-position: 0 0;
  color: black;
  transition: all 0.3s ease-in-out;
}

.mantine-UnstyledButton-root {
  opacity: 1 !important;
}

.toBlue {
  background: linear-gradient(
    to right,
    #197cc1 0%,
    #197cc1 50%,
    #00000000 50%,
    #00000000 100%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  transition: background-position 0.3s ease-in-out;
}

.toBlue:hover {
  background-position: 0 0;
  color: white;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 400px) {
  .tech__img__responsive {
    width: 64px;
  }
}
@media (max-width: 950px) {
  .width__resp {
    width: 100%;
  }
}

.feedback__scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 8px;
  background-color: #aaa;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("./assets/fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./assets/fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./assets/fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./assets/fonts/poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins-Extrabold";
  src: url("./assets/fonts/poppins/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: "Merry-Bold";
  src: url("./assets/fonts/merriweather-sans/MerriweatherSans-Bold.ttf");
}

@font-face {
  font-family: "Merry-Regular";
  src: url("./assets/fonts/merriweather-sans/MerriweatherSans-Regular.ttf");
}

.font-merry-bold {
  font-family: "Merry-Bold";
}

.font-merry-regular {
  font-family: "Merry-Regular";
}

.font-poppins-bold {
  font-family: "Poppins-Bold";
}

.font-poppins-medium {
  font-family: "Poppins-Medium";
}

.font-poppins-regular {
  font-family: "Poppins-Regular";
}

.font-poppins-semi-bold {
  font-family: "Poppins-SemiBold";
}

.font-poppins-extrabold {
  font-family: "Poppins-Extrabold";
}
